<template>
  <b-modal
    id="webview-prompt"
    ref="webview-prompt"
    v-model="displayModal"
    centered
    hide-footer
  >
    <template
      #modal-header
    >
      <b-button
        class="close btn-icon"
        variant="outline-secondary"
        size="sm"
        @click="displayModal=false"
      >
        <feather-icon
          icon="XIcon"
          size="26"
        />
      </b-button>
      <b-media
        no-body
      >
        <b-media-aside>
          <b-avatar
            rounded
            variant="primary"
            size="24"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="align-self-center">
          <span class="font-weight-bold">{{ $t('app.install') }}</span>
        </b-media-body>
      </b-media>

    </template>
    <b-row>
      <b-col>
        <p class="mb-2 mt-1">
          {{ $t('app.props') }}:
        </p>
        <b-alert
          variant="primary"
          show
        >
          <h4 class="alert-heading">
            <feather-icon
              icon="BellIcon"
              class="mr-50"
            />{{ $t('Notifications') }}
          </h4>
          <div class="alert-body">
            <about-notifications />
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item :title="`${$t('notification.types')}`">
                <notifications-types />
              </app-collapse-item>
            </app-collapse>
          </div>
        </b-alert>

      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="text-center">
        <get-it-on-google-play />
      </b-col>

    </b-row>

  </b-modal>
</template>

<script>
import {
  BRow, BCol, BAlert, BButton, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import 'animate.css'
import { ref } from '@vue/composition-api'
import GetItOnGooglePlay from '@/layouts/components/GetItOnGooglePlay.vue'
import NotificationsTypes from '@/views/apps/notification/NotificationsTypes.vue'
import AboutNotifications from '@/views/apps/notification/AboutNotifications.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BButton,
    BMediaAside,
    BMedia,
    BMediaBody,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    GetItOnGooglePlay,
    NotificationsTypes,
    AboutNotifications,
  },
  data() {
    return {
    }
  },
  created() {
    this.displayPrompt()
  },
  methods: {
    displayPrompt() {
      if (navigator.userAgent.includes('Android')) {
        if (!navigator.userAgent.includes('wv') && !navigator.userAgent.includes('WebView')) {
          this.displayModal = true
        }
      }
      return false
    },
  },
  setup() {
    const displayModal = ref(false)
    return {
      displayModal,
    }
  },

}
</script>
