<template>
  <div>
    <b-row class="text-center mt-1">
      <b-col>
        <span><b>{{ appName }}</b></span> - {{ $t('about_the_project') }}
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="my-2 mt-sm-0"
      >
        <div class="pb-1 pb-lg-0"><b-link :to="{path:`/faq` }">
          {{ $t('Have a question?') }}
        </b-link></div>
        <div class="pb-1 pb-lg-0"><b-link :to="{path:`/${$i18n.locale}/privacy-policy` } ">
          {{ $t('Privacy policy') }}
        </b-link></div>
<!--        <div class="pb-1 pb-lg-0"><b-link :to="{path:`/${$i18n.locale}/public-offer` }">-->
<!--          {{ $t('Public Offer') }}-->
<!--        </b-link></div>-->
        <div class="pb-1 pb-lg-0"><b-link :to="{path:`/pricing` }">
          {{ $t('Pricing') }}
        </b-link></div>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
<!--        <donates class="mb-2 mb-md-1" />-->
        <div class="d-flex justify-content-center align-items-center">
          <b-img
              :src="require('@/assets/images/icons/mastercard_icon.svg')"
              height="32px"
          />
          <b-img
              :src="visaImgUrl"
              height="32px"
          />
<!--          <get-it-on-google-play/>-->
        </div>
      </b-col>
    </b-row>
    <b-row class="text-center text-muted pt-1">
      <b-col>
        <p class="small">        ©
          2022 - {{ new Date().getFullYear() }}
          {{ appName }}
          {{ appVersion }}</p>
      </b-col>
    </b-row>
<!--    <web-view-prompt></web-view-prompt>-->
  </div>

</template>

<script>
import {
  BLink, BImg, BRow, BCol,
} from 'bootstrap-vue'
import Donates from '@/layouts/components/Donates.vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import { computed } from '@vue/composition-api'
import GetItOnGooglePlay from '@/layouts/components/GetItOnGooglePlay.vue'
import i18n from '@/libs/i18n'
import WebViewPrompt from '@/layouts/components/WebViewPrompt.vue'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    Donates,
    GetItOnGooglePlay,
    WebViewPrompt,
  },
  data() {
    return {
      appVersion: `v${process.env.VUE_APP_VERSION}`,
    }
  },
  computed: {
    visaImgUrl() {
      // eslint-disable-next-line global-require
      let img = require('@/assets/images/icons/Visa_Brandmark_Blue_RGB_2021.svg')
      if (this.skin === 'dark') {
        // eslint-disable-next-line global-require
        img = require('@/assets/images/icons/Visa_Brandmark_White_RGB_2021.svg')
        return img
      }
      return img
    },
    getItOnGooglePlayLogo() {
      console.log(i18n.locale)
      let logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_English.png')
      switch (i18n.locale) {
        case 'uk': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Ukranian.png')
          break
        case 'ru': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Russian.png')
          break
        case 'de': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_German.png')
          break
        case 'es': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Spanish.png')
          break
      }
      return logo
    },
  },
  setup() {
    const { appName } = $themeConfig.app
    const userAuthorized = computed(() => store.state.account.user.id)
    const {
      skin,
    } = useAppConfig()


    return {
      appName,
      skin,
      userAuthorized,
    }
  },
}
</script>
