<template>
  <div>
    <b-img
      :src="getItOnGooglePlayLogo"
      height="44px"
    />
  </div>

</template>

<script>
import {
  BLink, BImg,
} from 'bootstrap-vue'

import { computed } from '@vue/composition-api'

import i18n from '@/libs/i18n'

export default {
  components: {
    BLink,
    BImg,
  },

  computed: {
    // getItOnGooglePlayLogo() {
    //   let logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_English.png')
    //   switch (i18n.locale) {
    //     case 'uk': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Ukranian.png')
    //       break
    //     case 'ru': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Russian.png')
    //       break
    //     case 'de': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_German.png')
    //       break
    //     case 'es': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Spanish.png')
    //       break
    //   }
    //   return logo
    // },
  },
  setup() {
    const getItOnGooglePlayLogo = computed(() => {
      /* eslint-disable global-require */
      let logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_English.png')
      switch (i18n.locale) {
        case 'uk': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Ukranian.png')
          break
        case 'ru': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Russian.png')
          break
        case 'de': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_German.png')
          break
        case 'es': logo = require('@/assets/images/icons/GetItOnGooglePlay_Badge_Web_color_Spanish.png')
          break
        default: return logo
      }
      return logo
    })
    return {
      getItOnGooglePlayLogo,
    }
  },
}
</script>
