<template>
  <div class="my-1">

    <div
      v-for="message in aboutMessages"
      :key="message"
    >
      <p
        class="text-primary line-height-condensed"
        :class="size"
      >
        {{ $t(message) }}
      </p>
    </div>

  </div>

</template>

<script>

export default {
  props: {
    size: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  setup() {
    const aboutMessages = [
      'System messages',
      'Change in status of service request',
      'Changing the status of a proposal from a contractor',
    ]

    return {
      aboutMessages,
    }
  },
}
</script>
