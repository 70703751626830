<template>
  <div class="text-columns">
    <div
      v-for="notification in notifications"
      :key="notification"
      class="d-flex justify-content-start align-items-center mb-75"
    >
      <b-avatar

        size="32"
        class="mr-75"
        :variant="getNotificationVariant(notification)"
      >
        <feather-icon
          :icon="getNotificationIcon(notification)"
        />
      </b-avatar>
      <div>
        <div class="">
          {{ $t(getNotificationTitle(notification)) }}
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import {
  BAvatar,
  BMedia,

} from 'bootstrap-vue'

import { computed } from '@vue/composition-api'
import { formatDateTime } from '@/filters/dateTime'

import {
  notificationOptions,
} from './useNotification'

export default {
  components: {
    BAvatar,
    BMedia,

  },
  filters: {
    formatDateTime,
  },

  computed: {

  },
  created() {

  },
  methods: {

    getNotificationIcon(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.icon : 'ToolIcon'
    },
    getNotificationVariant(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.variant : 'secondary'
    },
    getNotificationTitle(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.title : 'Title'
    },
  },
  setup() {
    const { notificationTypes } = notificationOptions()

    const notifications = computed(() => Object.keys(notificationTypes))

    return {
      notifications,
      notificationTypes,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.author{
  bottom: -1px!important;
  right:0;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height: 15.85rem;
  display: block;
  align-items: center;
}
.card-img-top {
  max-height: 20em;
  object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
  background-color: #242B3D !important;
}

.ecommerce-application .grid-view {
  grid-template-columns: none;
}

.link{
  cursor: pointer;
}
.ecommerce-application .list-view .ecommerce-card .card-body {
  border-right: none;
}
.ecommerce-application .list-view .ecommerce-card{
  margin-bottom: 1rem;
  grid-template-columns: none;
}

@media (min-width: 767.98px) {
  .ecommerce-application .list-view .ecommerce-card.no-image,
  .ecommerce-application .grid-view{
    //grid-template-columns: 1fr 1fr;
  }

}
</style>
