<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.username }}
        </p>
        <span
          v-if="userData.roles"
          class="user-status "
        > <span class="text-uppercase">{{ userData.roles.join() }}</span>
          <span
            v-if="userData.subscriptions && userData.subscriptions.length"
          > | {{ userData.subscriptions.map(el=>el.label).join() }}</span>
        </span>

      </div>
      <b-avatar
        size="32"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        :badge-variant="userData.name?'success':'danger'"
        :class="isManagedUser()?'btn-danger':''"
      >
        <feather-icon
          v-if="!userData.name"
          icon="UserIcon"
          size="28"
        />
      </b-avatar>
    </template>

    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-profile'}"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="UserIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Profile</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-email' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="MailIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Inbox</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-todo' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="CheckSquareIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Task</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-chat' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="MessageSquareIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Chat</span>-->
    <!--    </b-dropdown-item>-->

    <!--    <b-dropdown-divider />-->
    <div v-if="userData.name">
      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>{{ $t('Settings') }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>{{ $t('LogOut') }}</span>
      </b-dropdown-item>
    </div>
    <div v-if="!userData.name">
      <b-dropdown-item
        :to="{ name: 'auth-register' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
        />
        <span>{{ $t('Register') }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'auth-login' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="LogInIcon"
          class="mr-50"
        />
        <span>{{ $t('LogIn') }}</span>
      </b-dropdown-item>
    </div>

    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-pricing' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="CreditCardIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Pricing</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-faq' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="HelpCircleIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>FAQ</span>-->
    <!--    </b-dropdown-item>-->

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'

import { useUserUi } from '@/views/apps/user/useUser'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    ...mapGetters({
      userData: 'account/user',
    }),
  },
  methods: {
  },
  setup() {
    const { isManagedUser, logout } = useUserUi()
    return {
      isManagedUser,
      logout,
    }
  },
}
</script>
<style lang="scss" >
.b-avatar.btn-danger .b-avatar-img img {
  width: 90%;
  height: 90%;
}
</style>
